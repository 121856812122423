import React from "react";
import Layout from "../components/layout";
import SEO from "../components/SEO";
import Store from "../components/Store";
import { GetStartedHeader } from "../images/get-started-header";
const GetStarted = ({ serverData, location }) => {
	return (
		<>
			<SEO title="Get Started" />
			<Layout location={location} lang={"en"}>
				<div className="w-11/12 xl:w-10/12 mx-auto">
					<GetStartedHeader className="w-full h-full" />
				</div>
				<Store lang="en" data={serverData.Data} />
			</Layout>
		</>
	);
};

export default GetStarted;
export async function getServerData() {
	try {
		const res = await fetch(
			`https://erp.buducloud.com/MobileApp/MobileBackend/public/index.php/WebApi/cloud/packages`,
			{
				method: "GET",
				headers: {
					accessToken:
						"e87db6afc72216aab3d9|#|#|#|71d34f3dabb724c836c200fba26c4b6ee43550cf",
				},
			}
		);

		if (!res.ok) {
			throw new Error(`Response failed`);
		}

		return {
			props: await res.json(),
		};
	} catch (error) {
		return {
			status: 500,
			headers: {},
			props: {},
		};
	}
}
